var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-user-place-type-switches"},[_c('div',{class:[
      'space-type-button',
      _vm.showSpace
        ? 'space-type-button-on'
        : 'space-type-button-off'
    ],on:{"click":() => {
      _vm.$store.dispatch(
        'filter/setConditions',
        { showSpace: !this.showSpace }
      )
    }}},[_vm._v(" スペース ")]),_c('div',{class:[
      'event-type-button',
      _vm.showEvent
        ? 'event-type-button-on'
        : 'event-type-button-off'
    ],on:{"click":() => {
      _vm.$store.dispatch(
        'filter/setConditions',
        { showEvent: !this.showEvent }
      )
    }}},[_vm._v(" イベント ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }