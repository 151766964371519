<template>
  <div class="current-location-button">
    <!-- Loading -->
    <div v-if="waitingCurrentLocation">
      <LoadingHeart />
    </div>

    <img
      v-else-if="currentLocation" 
      width="54px"
      height="54px"
      :src="require('@/assets/image/current-location/current_location_button_on.png')"
      @click="() => $emit('click-button')"
    />
    <img
      v-else
      width="54px"
      height="54px"
      :src="require('@/assets/image/current-location/current_location_button_off.png')"
      @click="() => $emit('click-button')"
    />
  </div>
</template>

<script>
import LoadingHeart from "@/assets/image/icon_current_location_loading.svg?component"
import { mapGetters } from 'vuex'

export default {
  name: 'AppUserCurrentLocationButton',
  components: {
    LoadingHeart
  },
  props: {
    waitingCurrentLocation: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters(
      'map',
      [ 'currentLocation' ]
    ),
  },
}
</script>

<style lang="scss" scoped>
</style>