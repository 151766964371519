 <template>
  <div class="app-user-place-type-switches">
    <div
      :class="[
        'space-type-button',
        showSpace
          ? 'space-type-button-on'
          : 'space-type-button-off'
      ]"
      @click="() => {
        $store.dispatch(
          'filter/setConditions',
          { showSpace: !this.showSpace }
        )
      }"
    >
      スペース
    </div>
    <div
      :class="[
        'event-type-button',
        showEvent
          ? 'event-type-button-on'
          : 'event-type-button-off'
      ]"
      @click="() => {
        $store.dispatch(
          'filter/setConditions',
          { showEvent: !this.showEvent }
        )
      }"
    >
      イベント
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppUserPlaceTypeSwitches',
  computed: {
    ...mapGetters('filter', [ 'showSpace', 'showEvent' ]),
  },
}
</script>

<style lang="scss" scoped>
.app-user-place-type-switches {
  display: flex;
  box-shadow: 0 4px 6px -4px rgba(0,0,0,0.6) inset;
  .space-type-button {
    flex-grow: 1;
    font-size: 12px;
    text-align: center;
    margin: 12px 16px;
    padding: 4px 8px;
    border-radius: 40px;
    border: solid 1px #12b6d4;
  }
  .space-type-button-on {
    color: #fff;
    background-color: #12b6d4;
  }
  .space-type-button-off {
    color: #12b6d4;
    background-color: #fff;
  }
  .event-type-button {
    flex-grow: 1;
    font-size: 12px;
    text-align: center;
    margin: 12px 16px;
    padding: 4px 8px;
    border-radius: 40px;
    border: solid 1px #95BB00;
  }
  .event-type-button-on {
    color: #fff;
    background-color: #95BB00;
  }
  .event-type-button-off {
    color: #95BB00;
    background-color: #fff;
  }
}
</style>