<template>
  <transition name="slide-fade">
    <div v-if="show" class="current-location-label">
      <div class="current-location-label-contents">
        <!-- Close button -->
        <b-button
          class="close-button"
          @click="() => $emit('hide')"
        >
          &#10005;
        </b-button>
        <!-- Text -->
        位置情報の使用がオフになっています
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'AppUserCurrentLocationLabel',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    waitingCurrentLocation: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}
 
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
 
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
}

.current-location-label-contents {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  background-color: #12B6D4;
  padding: 8px 16px;
}

.close-button {
  color: #12B6D4;
  background-color: #fff;
  border-color: #fff;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  font-size: 8px;
  padding: 0;
}

.enable-current-location-button {
  width: auto;
  font-size: 12px;
  text-align: center;
  padding: 4px 8px;
  border-radius: 40px;
  color: #12B6D4;
  border: solid 1px #12B6D4;
  background-color: #fff;
  margin-left: 8px;
  min-width: 80px;
}

.turn-on-text {
  margin-bottom: 0
}
</style>